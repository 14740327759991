<template>
  <div id="app">
    <div class="bgbox">
      <div class="parent">

        <div class="first" v-for="(item, index) in ArrayData" :key="index">

          <div class="name">
            <img v-if="index < 3" :src="images[index]" />

            {{ item.name }}

            ({{ item.nums }})

          </div>


        </div>

      </div>
    </div>
    <!-- <canvas class="confetti" id="canvas"></canvas> -->
    <!-- <script src="./assets/confetti.js"></script>
    <script src="./assets/script.js"></script>  -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      ArrayData: [],
      images: [
        require('../src/assets/images/first.png'),
        require('../src/assets/images/second.png'),
        require('../src/assets/images/third.png'),
        // 更多图片路径...
      ],
      intervalId: null, // 用于存储定时器ID
    }
  },
  mounted() {
    this.intervalId = setInterval(() => {
      this.getSomeData();
    }, 1000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // 清除定时器
      this.intervalId = null; // 将定时器ID重置为null
    }
  },
  methods: {
    getSomeData() {
      axios
        .get(
          "https://www.scaleph.cn/web/index.php?r=api/activity/program-list"
        )
        .then((response) => {
          // 处理响应数据
          console.log(response.data.data);
          this.ArrayData = response.data.data
        })
        .catch((error) => {
          // 处理错误情况
          console.error("Error:", error);
        });
    },

  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}

body {
  margin: 0 !important;
  overflow: hidden;
}

.last {
  margin-left: auto;
}

.bgbox {
  /* width: 100%;
  min-height: 100vh; */
  background-image: url("../src/assets/images/1.png");
  /* background-size: 100%; */
  background-repeat: no-repeat;
  /* 不重复背景图像 */
  background-size: cover;
  /* 背景图像覆盖整个元素，且保持宽高比 */
  /* padding-top: 10px;
   */
  /* overflow: hidden; */
  width: 100vw;
  /* 撑满整个视口宽度 */
  height: 100vh;
  /* 撑满整个视口高度 */
}

.parent {
  text-align: center;
  /* width: 40%; */
  height: 60%;
  overflow-y: auto;
  /* background-color: #fff; */
  /* margin: 1200px 0px 0 1700px; */
  /* margin: 20% 0 0 29%; */
  position: absolute;
  top: 40%;
  right: 36%;
  /* background-color: transparent; */
  letter-spacing: 1rem;
  padding-bottom: 2rem;
}

.parent::-webkit-scrollbar {
  width: 0;
  /* 隐藏滚动条 */
}

.parent::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* 设置滚动条的滑块为透明 */
}

.parent::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
  /* 滚动条的宽度 */
  height: 100%;
  background-color: transparent;
  /* 背景色设置为透明 */
}

.first,
.second,
.third {
  /* width: 100%;
  height: 200px; */
  /* line-height: 200px; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* margin-top: 4rem; */
  text-align: center;
  margin-top: 1rem;


}

img,
.img {
  vertical-align: bottom;
  width: 200px;
  height: 200px;
  margin-top: 3rem;
}

.name {
  /* width: 50%; */
  /* min-height: 200px; */
  /* text-align: center; */
  font-size: 12rem;
  color: yellow;
  margin-left: 0.5rem;
}

span {

  font-size: 12rem;
  color: yellow;
}



/* 
.confetti {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  user-select: none;
}

body {
  cursor: pointer;
}

.name {
  font-weight: 900;

  padding: 36px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 20%;
  color: rebeccapurple;

  font-size: 93px;

  text-shadow: 2px 4px 11px rgb(0 0 0 / 28%);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
} */
</style>
